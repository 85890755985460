export const laptopNum = 1440;

export const laptop = `${laptopNum}px`;

export const tabletNum = 1024;

export const tablet = `${tabletNum}px`;

export const bigMobileNum = 480;

export const bigMobile = `${bigMobileNum}px`;

export const mobileNum = 360;

export const mobile = `${mobileNum}px`;
